import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, FormControl, InputGroup } from 'react-bootstrap'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { MenuContext } from '../../../context/menu-context'
import { LanguageContext } from '../../../context/language-context'
import { PanelContext } from '../../../context/panel-context'
import { SearchResultProps } from '../../SearchPage/SearchResultItem'
import { API_ENDPOINT_AUTOSUGGEST, IS_DEV } from '../../../../config'
import useDebounce from '../../../hooks/useDebounce'
import { Panel } from '../../../types/tree'

interface Props {
  panels: Panel[]
  searchOnPanelChange?: boolean
  isMinimal?: boolean
}

const SearchBar = (props: Props) => {
  const {
    panels,
    isMinimal = false,
    searchOnPanelChange = true
  } = props

  const location = useLocation()
  let params = new URLSearchParams(location.search)
  let paramPanel = params.get('panel')
  if (paramPanel !== null) paramPanel = `/${paramPanel}`

  const panel = panels.find(panel => panel.panelSlug === paramPanel)
  const { title: initialTitle } = panel || { title: '' }

  const [selectedPanel, setSelectedPanel] = useState<string>(paramPanel ?? '')
  const [title, setTitle] = useState<string>(initialTitle)
  const { currentPanel } = useContext(PanelContext)

  const { t } = useTranslation()
  const { setOpen } = useContext(MenuContext)
  const { language, langPrefix } = useContext(LanguageContext)
  const searchUrl = IS_DEV ? 'http://localhost:5000' : location.origin

  const [query, setQuery] = useState('')
  const [suggestResults, setSuggestResults] = useState([])

  const debouncedQuery = useDebounce(query)

  let styles = {
    input: classNames('input-search-bar',
      { 'shadow': !isMinimal },
      { 'rounded pl-4': isMinimal }
    )
  }
  let placeholder = isMinimal ? '' : t('SearchBar.placeholder')

  const searchForSuggestions = async (queryString: string) => {
    let url = new URL(searchUrl + API_ENDPOINT_AUTOSUGGEST)
    url.searchParams.set('query', queryString)
    url.searchParams.set('panel', selectedPanel)
    url.searchParams.set('language', language)

    try {
      const response = await fetch(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      const data = await response.json()

      if (data.hasOwnProperty('error')) {
        setSuggestResults([])
      } else {
        setSuggestResults(data)
      }
    } catch (err) {
      console.log(err)
      setSuggestResults([])
    }
  }

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 0) {
      searchForSuggestions(debouncedQuery)
    }
  }, [debouncedQuery])

  useEffect(() => {
    const panel = panels.find(panel => panel.panelSlug === selectedPanel)
    const { title } = panel || { title: '' }
    setTitle(title)
  }, [selectedPanel, paramPanel])

  useEffect(() => {
    if (location.pathname !== `${langPrefix}/search`) {
      setQuery('')
    }
  }, [location.pathname])

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      onSearch(selectedPanel)
    }
  }

  const onSearch = (panel: string) => {
    if (!panel) {
      panel = panels.find(panel => panel.panelSlug === currentPanel)?.panelSlug || ''
    }

    let params = new URLSearchParams()
    if (query !== '') params.append('query', query)
    if (panel !== '') params.append('panel', panel.replace('/', ''))
    setOpen(false)
    navigate(`${langPrefix}/search?${params.toString()}`)
  }

  const handleSearchSuggestion = (title: string) => {
    setQuery(title)
    let params = new URLSearchParams()
    if (query !== '') params.append('query', title)
    setOpen(false)
    navigate(`${langPrefix}/search?${params.toString()}`)
  }

  const handleQueryChange = (e: { nativeEvent: { inputType: any }; target: { value: string } }) => {
    if (!e.nativeEvent.inputType) {
      handleSearchSuggestion(e.target.value)
    } else {
      setQuery(e.target.value)
    }
  }

  const getSubLabels = (subtitles: string[]) => {
    const subTitleInclude = subtitles.filter((sub: string) => sub.toLowerCase().includes(query.toLowerCase()))
    if (subTitleInclude.length > 0) {
      return subTitleInclude[0]
    }
  }

  return (
    <>
    <InputGroup
      size={isMinimal ? undefined : 'lg'}
    >
      {
        !isMinimal && (
          <InputGroup.Prepend
            className='d-none d-sm-block'
            style={{ zIndex: 1 }}
          >
            <Dropdown>
              <Dropdown.Toggle
                id={'dropdown-toggle-search'}
                // @ts-ignore
                variant={'gray-300'}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                }}
                size={isMinimal ? undefined : 'lg'}
              >
                {title || t('SearchBar.DropdownButton')}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className='ml-1 ml-sm-0 p-0 border-0 rounded-0 shadow'
              >
                {
                  selectedPanel !== '' && (
                    <Dropdown.Item
                      className='px-3 py-2'
                      onSelect={() => {
                        setSelectedPanel('')
                        searchOnPanelChange && onSearch('')
                      }}
                    >
                      {t('SearchBar.DropdownButton')}
                    </Dropdown.Item>
                  )
                }
                {
                  panels.map(panel => (
                      <Dropdown.Item
                        key={`search-item-${panel.title}`}
                        onSelect={() => {
                          console.log(panel.panelSlug)
                          setSelectedPanel(panel.panelSlug)
                          searchOnPanelChange && onSearch(panel.panelSlug)
                        }}
                        className='px-3 py-2 border-top'
                      >
                        {panel.title}
                      </Dropdown.Item>
                    )
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup.Prepend>
        )
      }
      {
        isMinimal && (
          <i
            className='fa fa-search position-absolute ml-2 text-gray-500'
            style={{ left: 0, top: '50%', zIndex: 100, transform: 'translateY(-50%)' }}
          />
        )
      }
      <FormControl
        list='data-list'
        placeholder={placeholder}
        className={styles.input}
        onChange={(e: any) => handleQueryChange(e)}
        value={query}
        onKeyDown={(e: any) => handleKeyDown(e.key)}
      />
      <>
        {suggestResults && suggestResults.length > 1 && (
            <datalist
                id='data-list'
                inputMode='search'
                style={{ zIndex: 10 }}
            >
              {suggestResults.filter((value: any, index: number, self: any) =>
                      index === self.findIndex((t: any) => (
                          t.title === value.title
                      ))
              ).slice(0,10).map((sr: SearchResultProps) => {
                const subLabel = getSubLabels(sr.subtitles)
                return (
                  <option
                    key={sr.path}
                    value={sr.title}
                    label={subLabel}
                  />
                )
              })}
            </datalist>
        )}
      </>
      {
        !isMinimal && (
          <InputGroup.Append
            style={{ zIndex: 0 }}
          >
            <Button
              // @ts-ignore
              variant={'primary'}
              onClick={() => onSearch(selectedPanel)}
            >
              <i className='mr-0 mr-sm-1 fa fa-search' />
              <span className='d-none d-sm-inline-block'>
                {t('SearchBar.button')}
              </span>
            </Button>
          </InputGroup.Append>
        )
      }
    </InputGroup>
    </>
  )

}
export default SearchBar
