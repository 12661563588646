import React, { useContext } from 'react'
import classNames from 'classnames'
import SearchBar from '../Various/SearchBar/SearchBar'
import { PanelContext } from '../../context/panel-context'
import { MenuContext } from '../../context/menu-context'
import SidebarProductList from './SidebarProductList'
import SidebarPanelList from './SidebarPanelList'
import logoHetznerOnline from '../../images/hetzner-logo.svg'
import LanguageDropdown from '../Various/LanguageDropdown/LanguageDropdown'
import { useHasMounted } from '../../hooks/useHasMounted'
import Link from '../Navigation/Link/Link'
import { Tree, Panel } from '../../types/tree'

interface Props {
  path: string
  tree: Tree
  isBaseLayout: boolean
}

const Sidebar = ({ path, tree, isBaseLayout }: Props) => {
  const { currentPanel } = useContext(PanelContext)
  const { open } = useContext(MenuContext)

  let searchParams = null
  let content = null

  const hasMounted = useHasMounted()

  if (!hasMounted) {
    if (!isBaseLayout) return null
    return (
      <div className='h-auto bg-white navbar-wrapper'>
        <nav
          className='sticky-top navbar-left d-flex flex-column justify-content-between'
          style={{ top: '65px' }}
        />
      </div>
    )
  }

  if (!isBaseLayout) {
    const { panels } = tree
    searchParams = panels
    content = (
      <SidebarPanelList
        panels={panels}
      />
    )

  } else {
    searchParams = []
    let panel = tree?.panels.find((panel: Panel) => panel.panelSlug === currentPanel) ?? null
    if (panel !== null) searchParams.push(panel)
    content = (
      <SidebarProductList
        panel={panel}
        currentPanel={currentPanel}
        path={path}
      />
    )
  }

  let styles = classNames('h-auto bg-white navbar-wrapper',
    { 'transition-navbar-left': open },
    { 'd-block d-md-none': !isBaseLayout }
  )

  return (
    <div className={styles}>
      <nav
        className='sticky-top navbar-left d-flex flex-column justify-content-between'
        style={{ top: '65px' }}
      >
        <div>
          <div className='p-3'>
            <Link classNames={'link-unstyled'} to='/'>
              <img
                height={21}
                src={logoHetznerOnline}
                alt=''
                className='logo-top-bar d-block d-md-none'
                style={{ marginBottom: '23px' }}
              />
            </Link>
            <SearchBar
              panels={searchParams}
              isMinimal={true}
            />
          </div>
          {content}
          <div className='border-top bg-white w-100' style={{ bottom: 0, left: 0 }}>
            <LanguageDropdown
              variant='outline-black'
              classNames='d-flex d-md-none my-4'
            />
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Sidebar
