import classNames from 'classnames'
import Link from '../Navigation/Link/Link'
import SidebarCategory from './SidebarCategory'
import React, { useContext, useEffect, useState } from 'react'
import { Collapse } from '@headtrip/components-react'
import { LanguageContext } from '../../context/language-context'
import SidebarProductArticle from './SidebarProductArticle'
import { useTranslation } from 'react-i18next'
import { Product, Article, Category } from '../../types/tree'

interface Props {
  panelSlug: string
  path: string
  product: Product
  defaultOpen: boolean
}

let isCategory = (param: Article | Category) => 'articles' in param

const compareFunc = (a: Article | Category, b: Article | Category) => {

  if (isCategory(a) && isCategory(b)) {
    return (a as Category).categoryName.localeCompare((b as Category).categoryName)
  }

  if (!isCategory(a) && isCategory(b)) {
    return a.title.localeCompare((b as Category).categoryName)
  }

  if (isCategory(a) && !isCategory(b)) {
    return (a as Category).categoryName.localeCompare(b.title)
  }

  if (!isCategory(a) && !isCategory(b)) {
    return a.title.localeCompare(b.title)
  }

  return 0
}

const SidebarProduct = (props: Props) => {
  let {
    panelSlug,
    path,
    product,
    defaultOpen = false
  } = props

  const {
    categories,
    productSlug,
    productName,
    articles
  } = product

  const { language } = useContext(LanguageContext)
  const { t } = useTranslation()
  let index = language === 'en' ? 0 : 1
  let currentProduct = path.match(/\/?[^\/]+|\//g) || []
  let isCurrentProduct = currentProduct[index + 1] === productSlug && currentProduct.length === index + 2
  let initialOpenState = currentProduct[index + 1] === productSlug || defaultOpen

  const [open, setOpen] = useState(initialOpenState)

  useEffect(() => {
    if (initialOpenState) setOpen(true)
  }, [path])

  let hasNestedCategories = false

  categories.map(category => {
    if (category.articles.length > 0) {
      hasNestedCategories = true
    }
  })

  let styles = {
    link: classNames({ 'bg-red text-white': isCurrentProduct }),
    icon: classNames('fa fa-chevron-up',
      { ' icon-transition-up': open },
      { ' icon-transition-down': !open }
    )
  }

  let LinkProductPage = null

  if (hasNestedCategories) {
    LinkProductPage = (
      <Link
        to={`${panelSlug}${productSlug}`}
        classNames={`d-block ml-3 px-1 py-1 link-unstyled hover-gray-300 ${styles.link}`}
      >
        <small className='d-inline-block font-weight-bold '>
          {t('Sidebar.ShowAll')}
        </small>
      </Link>
    )
  }

  const items = [...articles, ...categories]
      .sort(compareFunc)
      .sort((a, b) => b.priority - a.priority)

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-between cursor-pointer d-block px-3 py-2 border-top border-width-2`}
        onClick={() => setOpen(!open)}
      >
        <h6 className='text-transform-none m-0'>{productName}</h6>
        <i
          style={{ fontSize: '70%' }}
          className={styles.icon}
        />
      </div>
      <Collapse
        in={open}
      >
        <div>
          {LinkProductPage}
          {
            items.map((comp, index) => {
              if (isCategory(comp)) {
                let category = comp as Category
                return (
                  <SidebarCategory
                    key={`nav-link-cat-${language}-${panelSlug}-${productSlug}-${category.categorySlug}`}
                    path={path}
                    panelSlug={panelSlug}
                    productSlug={productSlug}
                    category={category}
                  />
                )
              } else {
                let article = comp as Article
                return (
                  <SidebarProductArticle
                    key={`nav-link-article-${index}`}
                    article={article}
                  />
                )
              }
            })
          }
        </div>
      </Collapse>
    </>
  )
}

export default SidebarProduct
