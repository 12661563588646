import React, { useEffect, useState } from 'react'

interface State {
  currentPanel: string,
  setCurrentPanel: (panel: string) => void
}

const getInitialState = (): State => {
  return {
    currentPanel: '',
    // tslint:disable-next-line:no-empty
    setCurrentPanel: (panel: string) => {}
  }
}

export const PanelContext = React.createContext<State>(getInitialState())

interface Props {
  path: string
  children: React.ReactNode
}

const PanelProvider = ({ path, children }: Props) => {
  const panelSlug = path.replace('/de/', '/').match(/\/?[^\/]+|\//g) || ['/']

  const [currentPanel, setCurrentPanel] = useState<string>(panelSlug[0])

  useEffect(() => {
    setCurrentPanel(panelSlug[0])
  }, [path])

  return (
    <PanelContext.Provider
      value={{ currentPanel, setCurrentPanel }}
    >
      {children}
    </PanelContext.Provider>
  )
}

export default PanelProvider
