import React, { useContext } from 'react'
import { MenuContext } from '../../context/menu-context'
import classnames from 'classnames'

const MenuOverlay = () => {
  const { open, setOpen } = useContext(MenuContext)
  let styles = classnames('menu-overlay position-absolute', { 'menu-overlay-open': open })

  return (
    <div
      onClick={() => setOpen(false)}
      className={`${styles}`}
    />
  )
}

export default MenuOverlay
