import { useTranslation } from 'react-i18next'
import React from 'react'
import { FooterLink } from './FooterLink'

interface FooterLinkListProps {
  linkColorClass: string
  orientation: 'center' | 'left' | 'right'
  isSmall: boolean
}

export const FooterLinkList = ({ linkColorClass, orientation, isSmall }: FooterLinkListProps) => {
  const { t } = useTranslation()

  const linkList = [
    {
      link_title: t('Footer.Legal'),
      url: 'https://www.hetzner.com/rechtliches/impressum'
    },
    {
      link_title: t('Footer.Privacy'),
      url: 'https://www.hetzner.com/rechtliches/datenschutz'
    },
    {
      link_title: t('Footer.Policy'),
      url: 'https://www.hetzner.com/rechtliches/system-policies'
    },
    {
      link_title: t('Footer.Terms'),
      url: 'https://www.hetzner.com/rechtliches/agb'
    }
  ]

  return (
        <ul className={`list-unstyled list-inline text-center text-lg-${orientation} m-0 footer-links-list`}>
            {
                linkList.map((link, index) => {
                  return (
                    <FooterLink
                        index={index}
                        key={index}
                        url={link.url}
                        linkColorClass={linkColorClass}
                        link_title={link.link_title}
                        isSmall={isSmall}
                    />
                  )
                })
            }
        </ul>
  )
}
