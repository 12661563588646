import { Dropdown } from 'react-bootstrap'
import { Link } from 'gatsby'
import React from 'react'
import { useLocation } from '@reach/router'

interface LanguageDropdownItemProps {
  language: string
  translatedLang: string
  link: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LanguageDropdownItem = ({ language, translatedLang }: LanguageDropdownItemProps) => {
  // const { setLanguage } = useContext(LanguageContext)
  const location = useLocation()
  const { pathname, search } = location

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getLink = (pathname: string) => {
    let replaceVal = '/de/'

    if (pathname === '/de') {
      replaceVal = '/de'
    }

    let basePath = pathname.replace(replaceVal, '/')
    if (search.length > 0) basePath = basePath + search
    if (language === 'en') {
      return basePath
    } else {
      return '/de' + basePath
    }
  }

  return (
    <Dropdown.Item
      key={'key-dropdown-apps'}
      className='p-0'
      as='li'
    >
      <Link
        to={getLink(pathname)}
        className='dropdown-link link-unstyled'
        target='_blank'
      >
        <div
          className='d-flex align-items-center px-3 py-2 border-bottom text-shadow-none'
        >
          <strong>{translatedLang}</strong>
        </div>
      </Link>
    </Dropdown.Item>
  )
}

export default LanguageDropdownItem
