import React, { useEffect, useState } from 'react'
import { DEFAULT_LANGUAGE_KEY } from '../constants/languages'
import { useTranslation } from 'react-i18next'

interface State {
  language: string,
  langPrefix: '' | '/de',
  setLanguage: (language: string) => void
}

const getInitialState = (pageLanguage: string): State => {
  return {
    language: pageLanguage,
    langPrefix: pageLanguage === 'en' ? '' : '/de',
    // tslint:disable-next-line:no-empty
    setLanguage: () => {}
  }
}

export const LanguageContext = React.createContext<State>(getInitialState(DEFAULT_LANGUAGE_KEY))

interface PageContext {
  language: string
}

interface Props {
  children: React.ReactNode
  pageContext: PageContext
  path: string
}

const LanguageProvider = (props: Props) => {
  const { pageContext } = props
  const { language: pageLanguage } = pageContext

  const [language, setLanguage] = useState<string>(pageLanguage)
  const { i18n } = useTranslation()

  useEffect(
    () => {
      setLanguage(pageLanguage)
      i18n.changeLanguage(pageLanguage)
    },
    [pageLanguage]
  )

  return (
    <LanguageContext.Provider
      value={{
        language,
        langPrefix: language === 'en' ? '' : '/de',
        setLanguage
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
