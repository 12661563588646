import { Dropdown } from 'react-bootstrap'
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { LanguageContext } from '../../../context/language-context'
import { PanelContext } from '../../../context/panel-context'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../../types/tree'

interface PanelSwitcherItem {
  panel: Panel
  langPrefix: string
}

const PanelSwitcherItem = (props: PanelSwitcherItem) => {
  const { panel, langPrefix } = props
  const { panelSlug, title } = panel
  const { setCurrentPanel } = useContext(PanelContext)

  return (
    <Dropdown.Item
      as={'li'}
      className={`d-flex align-items-center border-bottom p-0`}
      onClick={() => setCurrentPanel(panelSlug)}
    >
      <Link
        to={`${langPrefix}${panelSlug}`}
        className='link-unstyled px-3 py-2 w-100'
      >
        {title}
      </Link>
    </Dropdown.Item>
  )
}

interface PanelSwitcherProps {
  panels: Panel[] | null
}

const PanelSwitcher = (props: PanelSwitcherProps) => {
  const { panels } = props
  const { currentPanel } = useContext(PanelContext)
  const { langPrefix } = useContext(LanguageContext)
  const { t } = useTranslation()

  let toggleName = t('PanelSwitcher.ChoosePlatform')

  if (panels === null) {
    return null
  }

  const panel = panels.find(panel => panel.panelSlug === currentPanel)
  const { title } = { ...panel }
  toggleName = typeof title !== 'undefined' ? title : toggleName

  return (
    <Dropdown
      className={'d-none d-md-flex justify-content-center'}
    >
      <Dropdown.Toggle
        className={'border-0 mb-0 dropdown-no-hover d-flex align-items-center font-family-base font-weight-bold'}
        variant={'white'}
      >
      <span
        className='text-overflow-ellipsis'
        style={{ maxWidth: '140px' }}
      >
        {toggleName}
      </span>
        <i
          className='fas fa-chevron-down ml-1'
          style={{ fontSize: '70%' }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        as='div'
        className='mt-1 p-0 border-0 rounded-0 shadow'
      >
        <ul
          className='p-0 m-0'
          style={{ maxHeight: '50vh', overflowY: 'auto' }}
        >
          {
            panels.filter(panel => panel.title !== currentPanel).map((panel, index) => {
              return (
                <PanelSwitcherItem
                  key={`panel-item-${index}`}
                  panel={panel}
                  langPrefix={langPrefix}
                />
              )
            })
          }
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PanelSwitcher
