export const SUPPORTED_LANGUAGES = [
  {
    key: 'de',
    flagCode: 'de',
    translatedLang: 'Deutsch'
  },
  {
    key: 'en',
    flagCode: 'us',
    translatedLang: 'English'
  }
]

export const DEFAULT_LANGUAGE_KEY = 'en'
