import Link from '../Navigation/Link/Link'
import React from 'react'
import { Panel } from '../../types/tree'

const SidebarPanelList = ({ panels }: { panels: Panel[] }) => {
  return (
    <>
      {
        panels.map((panel: Panel) => {
          const {
            title,
            panelSlug
          } = panel

          return (
            <Link
              key={`${title}-link`}
              classNames={'text-black text-decoration-none'}
              to={panelSlug}
            >
              <h6
                className={`hover-gray-300 d-flex align-items-center justify-content-between px-3 py-2 m-0 text-transform-none hover-gray-300`}
              >
                {title}
                <i
                  style={{ fontSize: '70%' }}
                  className='fa fa-chevron-right'
                />
              </h6>
            </Link>
          )
        })
      }
    </>
  )
}

export default SidebarPanelList
