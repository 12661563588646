import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby-link'
import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { Article } from '../../types/tree'

interface Props {
  article: Article
}

const SidebarProductArticle = (props: Props) => {
  const { article } = props
  const { _slug, title } = article
  const location = useLocation()
  let initialState = location.pathname === _slug || location.pathname === `${_slug}/`
  const [isActive, setIsActive] = useState<boolean>(initialState)

  useEffect(() => {
    if (location.pathname === _slug || location.pathname === `${_slug}/`) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [location.pathname])

  const styles = classNames({
    'bg-red text-white': isActive
  })

  return (
    <Link
      to={_slug}
      className={'link-unstyled d-block '}
      style={{ textDecoration: 'none' }}
    >
      <small className={`d-block ml-3 px-1 py-1 hover-gray-300 ${styles}`}>
        {title}
      </small>
    </Link>
  )
}

export default SidebarProductArticle
