import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const NoJavascriptNotice = () => {
  const { t } = useTranslation()
  return (
    <noscript>
      <Container className='my-5'>
        <Row className='d-flex align-items-center justify-content-center flex-column text-center'>
          <p><i className='fa fa-4x fa-exclamation-triangle text-red' /></p>
          <h2 className='text-transform-none'>{t('NoJavascriptNotice.Headline')}</h2>
          <p>
            {t('NoJavascriptNotice.Text')}
          </p>
          <a
            className='btn btn-sm btn-black'
            href={t('NoJavascriptNotice.Link')}
          >
            {t('NoJavascriptNotice.Button')}
          </a>
        </Row>
      </Container>
    </noscript>
  )
}
export default NoJavascriptNotice
