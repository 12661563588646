import React, { ReactNode, useContext } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { LanguageContext } from '../../../context/language-context'
import { MenuContext } from '../../../context/menu-context'

interface Props {
  children: ReactNode
  to: string
  classNames?: string,
  style?: any
}

const Link = ({ children, to, classNames, ...style }: Props) => {
  if (typeof classNames === 'undefined') classNames = ''
  const internal = /^\/(?!\/)/.test(to)
  // Use gatsby-link for internal links, and <a> for others
  if (internal) {
    // if classNames have not been definied take default style, otherwise use params as style
    classNames = classNames.length > 0 ? classNames : 'link-single-internal'

    const { langPrefix } = useContext(LanguageContext)
    const { setOpen } = useContext(MenuContext)

    return (
      <GatsbyLink
        to={`${langPrefix}${to}`}
        {...style}
        className={classNames}
        onClick={() => setOpen(false)}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} {...style} className={classNames} target='_blank'>
      {children}
    </a>
  )
}

export default Link
