import SidebarProduct from './SidebarProduct'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import Link from '../Navigation/Link/Link'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../types/tree'

interface NavbarLeftProductListProps {
  currentPanel: string
  path: string
  panel: Panel | null
}

const SidebarProductList = (props: NavbarLeftProductListProps) => {
  const {
    currentPanel,
    path,
    panel
  } = props
  const { t } = useTranslation()

  if (panel === null) return null

  let isPanelOverview = panel?.panelSlug.includes(path.replace('/de', ''))
  let styles = classNames('hover-gray-300 text-black ', { 'bg-red text-white': isPanelOverview })
  if (typeof panel === 'undefined') {
    return null
  }
  let { products } = panel

  return (
    <>
      <Link
        classNames={'text-decoration-none'}
        to={currentPanel}
      >
        <h6 className={`px-3 py-2 m-0 text-transform-none ${styles}`}>
          {t('Sidebar.Overview')}
        </h6>
      </Link>
      {
        products.map((product, index) => {
          return (
            <SidebarProduct
              key={`product-${index}`}
              product={product}
              panelSlug={currentPanel}
              path={path}
              defaultOpen={products.length === 1}
            />
          )
        })
      }
    </>
  )
}

export default SidebarProductList
