import React, { useContext } from 'react'
import Link from '../Link/Link'
import { Navbar, Button } from '@headtrip/components-react'
import AppSwitcher from '../AppSwitcher/AppSwitcher'
import logo from '../../../images/hetzner-logo.svg'
import PanelSwitcher from '../PanelSwitcher/PanelSwitcher'
import { MenuContext } from '../../../context/menu-context'
import LanguageDropdown from '../../Various/LanguageDropdown/LanguageDropdown'
import { Panel } from '../../../types/tree'

interface Props {
  currentPanel?: string | undefined
  siteTitle?: string
  panels: Panel[]
}

const NavBarTop = (props: Props) => {
  const { siteTitle, panels } = props
  const { setOpen, open } = useContext(MenuContext)

  return (
    <Navbar
      bg={'white'}
      className='py-0 shadow-sm'
      style={{ minHeight: '64px', padding: '25px', zIndex: 2, width: '100%', position: 'fixed' }}
    >
      <Navbar.Brand
        as={
          () => {
            return (
                <Link
                    to={'/'}
                    classNames={'d-flex align-items-center mr-0 py-0 mr-4 border-right-1 link-unstyled shadow-none'}
                >
                  <img
                      className='logo-top-bar'
                      height={21}
                      src={logo}
                      alt='Logo Hetzner Online GmbH'
                  />
                  <span className='d-none d-sm-block ml-2 h5 mb-0'>{siteTitle}</span>
                </Link>
            )
          }
        }
      />
      <LanguageDropdown
        classNames={'d-none d-md-block'}
        variant={'white'}
        isTopBar={true}
      />
      <PanelSwitcher
        panels={panels}
      />
      <Navbar.Collapse>
        <AppSwitcher />
        <Button
          className={'d-block d-md-none ml-1'}
          variant={'white'}
          onClick={() => setOpen(!open)}
        >
          <i
            className='fa fa-bars d-flex align-items-center'
            style={{ fontSize: '21px' }}
          />
        </Button>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavBarTop
