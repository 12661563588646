import { useState, useEffect } from 'react'

/**
 * Returns a debounced value. Returns the initial value, or the updated
 * value when the debounceTime times out
 * @param initialValue The initial value
 * @param debounceTime in ms
 */
const useDebounce = (initialValue: string, debounceTime: number = 150) => {
  const [debouncedValue, setDebouncedValue] = useState(initialValue)

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setDebouncedValue(initialValue)
    }, debounceTime)

    return () => clearTimeout(timeoutRef)
  })

  return debouncedValue
}

export default useDebounce
