import React, { ReactNode } from 'react'
import NavBarTop from '../Navigation/NavBar/NavBarTop'
import { LayoutWrapper } from '../layout'
import { Panel } from '../../types/tree'

function TestsuiteLayout ({ children, panels }: { children: ReactNode, panels: Panel[] }) {
  return (
    <div className={`document-root`}>
      <NavBarTop
        panels={panels}
        siteTitle={'Docs'}
      />
      <div className='position-relative d-flex' style={{ flex: '1 1 100%' }}>
        <LayoutWrapper
          isBaseLayout={false}
        >
          {children}
        </LayoutWrapper>
      </div>
    </div>
  )
}

export default TestsuiteLayout
