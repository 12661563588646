import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { HOSupplementSection } from '@headtrip/components-react/'
import { useTranslation } from 'react-i18next'
import { FooterLinkList } from './FooterLinkList'

interface FooterProps {
  footerColor: string,
  supplementColor: string,
  hasSupplement: boolean,
  mirrorSupplements: boolean,
  showCopyRight: boolean,
  showLinks: boolean
}

const HOFooter = (props: FooterProps) => {
  const {
    footerColor,
    supplementColor,
    hasSupplement,
    mirrorSupplements,
    showCopyRight,
    showLinks
  } = props

  const { t } = useTranslation()

  let isBlack = footerColor === 'black'
  let isWhite = footerColor === 'white'
  let footerClasses = classNames(`pt-4 pb-3`,
    { 'bg-black text-white ': isBlack },
    { 'bg-white text-black': isWhite }
  )
  let linkClasses = classNames(
    { 'text-white ': isBlack },
    { 'text-black': isWhite }
  )

  return (
    <footer>
      {
        hasSupplement &&
        <HOSupplementSection color={mirrorSupplements ? footerColor : supplementColor} />
      }

      <div className={footerClasses}>
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className='text-center'>
                {showCopyRight && `© ${new Date().getFullYear()}. Hetzner Online GmbH. ${t('Footer.Rights')}.`}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {
                showLinks && (
                  <FooterLinkList
                    isSmall={false}
                    orientation='center'
                    linkColorClass={linkClasses}
                  />
                )
              }
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export { HOFooter }
