import React, { ReactNode, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import linksJson from './../../utils/redirects/client.json'

interface Props {
  children: ReactNode
}

const RedirectWrapper = (props: Props) => {
  const { children } = props
  const location = useLocation()
  const { pathname, hash } = location

  useEffect(() => {
    const { to } = linksJson.links.find(link => {
      return link.from === pathname + hash
    }) ?? { to: '' }
    if (to.length > 0) navigate(to)
  }, [])

  return (
    <>
      {children}
    </>
  )
}

export default RedirectWrapper
