import React from 'react'

interface FooterLinkProps {
  linkColorClass: string,
  index: string | number,
  url: string,
  link_title: string
  isSmall: boolean
}

export const FooterLink = ({ linkColorClass, index, url, link_title: linkTitle , isSmall }: FooterLinkProps) => {
  const style = isSmall ? { fontSize: '80%' } : {}
  return (
    <li
        key={`footer-link-${index}`}
        className='list-inline-item m-0 px-1'
        style={style}
    >
        <a
            className={`font-weight-bold text-decoration-none ${linkColorClass}`}
            href={url}
            target='_blank'
        >
            {linkTitle}
        </a>
    </li>
  )
}
