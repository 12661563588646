import SidebarArticle from './SidebarArticle'
import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../context/language-context'
import classNames from 'classnames'
import { Collapse } from '@headtrip/components-react'
import { Category } from '../../types/tree'

interface Props {
  path: string
  productSlug: string
  panelSlug: string
  category: Category
}

const SidebarCategory = (props: Props) => {
  const {
    path,
    panelSlug,
    productSlug,
    category
  } = props

  const {
    articles,
    categorySlug,
    categoryName
  } = category

  const { langPrefix } = useContext(LanguageContext)
  const isCurrentProduct = (`${langPrefix}${panelSlug}${productSlug}${categorySlug}` === path)
  const initialOpenState = path.startsWith(`${langPrefix}${panelSlug}${productSlug}${categorySlug}`)

  const [open, setOpen] = useState(initialOpenState)

  useEffect(() => {
    if (initialOpenState) setOpen(true)
  }, [path])

  let style = {
    headline: classNames('d-flex justify-content-between ml-3 pl-1 pr-3 py-1 cursor-pointer align-items-center hover-gray-300',
      { 'bg-red text-white': isCurrentProduct }
    ),
    icon: classNames('fa fa-chevron-up',
      { ' icon-transition-up': open },
      { ' icon-transition-down': !open }
    )
  }

  return (
    <div>
      <div
        className={style.headline}
        onClick={() => setOpen(!open)}
      >
        <small>
          {categoryName}
        </small>
      <i
        className={style.icon}
        style={{ fontSize: '70%' }}
      />
      </div>
      <Collapse
        in={open}
      >
        <div className='pb-2'>
          {
            articles.map((article, index) => (
                <SidebarArticle
                  key={`article-${index}`}
                  path={path}
                  {...article}
                />
              )
            )
          }
        </div>
      </Collapse>
    </div>
  )
}

export default SidebarCategory
