import React, { ReactNode } from 'react'
import { HOFooter } from './Navigation/Footer/Footer'

import NavBarTop from './Navigation/NavBar/NavBarTop'
import '../styles/styles.scss'
import favicon from '../images/favicon.ico'
import Sidebar from './Sidebar/Sidebar'
import MenuOverlay from './Layout/MenuOverlay'
import '../i18n/i18'
import FooterMinimal from './Navigation/Footer/FooterMinimal'
import NoJavascriptNotice from './Various/NoJavascriptNotice/NoJavascriptNotice'
import { useTranslation } from 'react-i18next'
import TestsuiteLayout from './Test-Suite/testsuite-layout'
import { Tree } from '../types/tree'

export interface Location {
  pathname: string
}

interface Props {
  children: ReactNode
  currentPanel?: string
  pageContext: PageContext
  path: string
}

interface PageContext {
  tree: Tree
  isBaseLayout: boolean
  title?: string
  frontmatter: Frontmatter
  isTestSuite?: boolean
}

const getMetaDescription = (isBaseLayout: boolean, frontmatter: Frontmatter) => {
  const { t } = useTranslation()
  if (isBaseLayout) {
    if (frontmatter?.meta_description ?? undefined) {
      return frontmatter.meta_description
    } else {
      return ''
    }
  } else {
    return t('layout.Meta.Description')
  }
}

const LayoutWrapper = ({ children, isBaseLayout }: { children: ReactNode, isBaseLayout: boolean }) => {
  if (isBaseLayout) {
    return (
      <div
        className='d-flex flex-column justify-content-between layout-wrapper'
        style={{ flex: '1 1 100%' }}
      >
        <main className='w-100'>
          {children}
        </main>
          {isBaseLayout && <FooterMinimal />}
      </div>
    )
  } else {
    return (
      <main className='w-100' style={{ zIndex: 0 }}>
        {children}
      </main>
    )
  }
}

const Layout = (props: Props) => {
  const {
    children,
    pageContext,
    path
  } = props

  if (!('tree' in pageContext)) return null
  const { tree, isBaseLayout, isTestSuite } = pageContext
  const { panels } = tree

  // Seperate Layouting for test-suite - no footer, no sidebar
  if (isTestSuite) {
    return (
        <TestsuiteLayout
            panels={panels}
            children={children}
        />
    )
  }

  return (
      <div className={'document-root'}>
        <NavBarTop
            panels={panels}
            siteTitle={'Docs'}
        />
        <NoJavascriptNotice />
        <MenuOverlay />
        <div className='position-relative d-flex mt-5' style={{ flex: '1 1 100%' }}>
          <Sidebar
              isBaseLayout={isBaseLayout}
              tree={tree || null}
              path={path}
          />
          <LayoutWrapper
              isBaseLayout={isBaseLayout}
          >
            {children}
          </LayoutWrapper>
        </div>
        {
          !isBaseLayout &&
          (
            <HOFooter
                footerColor={'black'}
                hasSupplement={true}
                mirrorSupplements={false}
                showCopyRight={true}
                showLinks={true}
                supplementColor={'gray-200'}
            />
          )
        }
      </div>
  )
}

export const Head = (props: any) => {
  const { isBaseLayout, title, frontmatter } = props.pageContext
  const metaTitle = typeof title !== 'undefined' ? `${title} - ` : ''
  const metaDescription = getMetaDescription(isBaseLayout, frontmatter)

  return (
      <>
        <title>{metaTitle}</title>
        <meta name='description' content={metaDescription} />
        <link rel='shortcut icon' type='image/ico' href={favicon} />
      </>
  )
}

export { LayoutWrapper }
export default Layout
