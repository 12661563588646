import classNames from 'classnames'
import { Link } from 'gatsby-link'
import React, { useContext } from 'react'
import { MenuContext } from '../../context/menu-context'
import { Article } from '../../types/tree'

interface Props extends Article {
  path: string
}

const SidebarArticle = (props: Props) => {
  const {
    path,
    _slug,
    title
  } = props
  const { setOpen } = useContext(MenuContext)

  let isCurrentArticle = _slug === path
  let styles = classNames({ 'bg-red text-white': isCurrentArticle })

  return (
    <Link

      className={`link-unstyled d-block border-left border-gray-300 border-width-2 `}
      to={_slug}
      onClick={() => setOpen(false)}
      style={{ marginLeft: '25px' }}
    >
      <small className={`d-block ml-1 hover-gray-300 px-1 py-1 ${styles}`}>
        {title}
      </small>
    </Link>
  )
}

export default SidebarArticle
