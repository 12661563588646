import { Dropdown } from 'react-bootstrap'
import React, { useContext } from 'react'
import LanguageDropdownItem from './LanguageDropdownItem'
import { SUPPORTED_LANGUAGES } from '../../../constants/languages'
import { LanguageContext } from '../../../context/language-context'
import classnames from 'classnames'

interface Language {
  key?: string
  flagCode: string
  translatedLang: string
  link?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getLanguageInfo = (language: string) => {
  // get the translated language name and the flag code for flag-icon-css
  const {
    flagCode,
    translatedLang
  } = SUPPORTED_LANGUAGES
    .find((lang) => lang.key === language) as Language
  return { translatedLang, flagCode }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LanguageDropdownToggle = ({ language }: { language: string }) => {
  const { translatedLang } = getLanguageInfo(language)

  return (
    <>
      <i
        style={{ fontSize: '90%' }}
        className='fas fa-globe-americas mr-1'
      />

      <span className=''>
        {translatedLang}
      </span>
      <i
        className='fas fa-chevron-down ml-1'
        style={{ fontSize: '70%' }}
      />
    </>
  )
}

interface Props {
  classNames?: string
  variant: 'white' | 'outline-black'
  isTopBar?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LanguageDropdown = ({ classNames, variant, isTopBar = false }: Props) => {
  const languageItemData: (Language[]) = SUPPORTED_LANGUAGES
  if (languageItemData.length <= 1) return null
  const styles = classnames(
    'justify-content-center',
    { 'd-none d-md-flex': isTopBar },
    { 'd-flex': !isTopBar },
    classNames ?? ''
  )

  const stylesToggle = classnames('d-flex align-items-center',
    { 'pl-4 pl-sm-3 dropdown-no-hover font-family-base font-weight-bold mt-0 border-0': isTopBar },
    { 'btn-sm mt-3 mt-lg-0 dropdown-no-icon': !isTopBar }
  )

  const stylesMenu = classnames('pt-1 p-0 border-0 rounded-0 shadow menu-center',
    { 'ml-3': isTopBar }
  )
  const { language: currentLanguage } = useContext(LanguageContext)

  return (
    <Dropdown
      className={styles}
    >
      <Dropdown.Toggle
        id='dropdown-apps'
        variant={variant}
        className={stylesToggle}
        data-toggle='dropdown'
        data-display='static'
      >
        <LanguageDropdownToggle
          language={currentLanguage}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        as='ul'
        className={stylesMenu}
        flip={true}
      >
        {
          languageItemData.map((language: Language, index: number) => {
            if (typeof language.key === 'undefined') language.key = ''

            return (
              <LanguageDropdownItem
                key={`lang-item-${index}`}
                language={language.key}
                translatedLang={language.translatedLang}
                link={language.link ?? '/'}
              />
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageDropdown
