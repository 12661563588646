exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-panel-tsx": () => import("./../../../src/templates/panel.tsx" /* webpackChunkName: "component---src-templates-panel-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-test-suite-tsx": () => import("./../../../src/templates/test-suite.tsx" /* webpackChunkName: "component---src-templates-test-suite-tsx" */)
}

