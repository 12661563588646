import React from 'react'
import { FooterLinkList } from './FooterLinkList'

const FooterMinimal = () => {
  return (
    <footer
      className='mr-0 mr-lg-3 mb-0 mb-lg-2 d-flex flex-column flex-xl-row justify-content-center justify-content-lg-end align-items-center'
    >
      <FooterLinkList
        isSmall={true}
        orientation='right'
        linkColorClass='text-gray-600'
      />

      <span className='ml-3 text-gray-600' style={{ fontSize: '80%' }}>
        {` © ${ new Date().getFullYear() }. Hetzner Online GmbH. All Rights Reserved.`}
      </span>
    </footer>
  )
}

export default FooterMinimal
