import React, { useState } from 'react'

interface State {
  open: boolean
  setOpen: (open: boolean) => void
}

const getInitialState = (): State => {
  return {
    open: false,
    // tslint:disable-next-line:no-empty
    setOpen: (open: boolean) => {}
  }
}

export const MenuContext = React.createContext<State>(getInitialState())

interface Props {
  children: React.ReactNode
}

const MenuProvider = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <MenuContext.Provider
      value={{ open, setOpen }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
