import React from 'react'
import { Dropdown } from 'react-bootstrap'
import iconAppSwitcher from '../../../images/app-switcher.svg'
import iconRobot from '../../../images/icon-hetzner-robot.svg'
import iconCloud from '../../../images/icon-hetzner-cloud.svg'
import iconWebsite from '../../../images/icon-hetzner-website.svg'
import iconDNSConsole from '../../../images/icon-hetzner-dns-console.svg'

const hetznerApps = [
  {
    id: 0,
    url: 'https://robot.hetzner.com/',
    name: 'Robot',
    logo: iconRobot,
    alt: 'Icon Robot Hetzner'
  },
  {
    id: 1,
    url: 'https://console.hetzner.cloud/',
    name: 'Cloud',
    logo: iconCloud,
    alt: 'Icon Hetzner Cloud'
  },
  {
    id: 2,
    url: 'https://dns.hetzner.com/',
    name: 'DNS',
    logo: iconDNSConsole,
    alt: 'Icon Hetzner DNS'
  },
  {
    id: 3,
    url: 'https://www.hetzner.com/',
    name: 'Website',
    logo: iconWebsite,
    alt: 'Icon Hetzner Website'
  }]

const AppSwitcherButton = () => (
  <Dropdown.Toggle
    id='dropdown-apps'
    variant={'white'}
    className='btn-sm d-flex align-items-center'
    data-toggle='dropdown'
    data-display='static'
  >
    <img
      src={iconAppSwitcher}
      width={21}
      height={21}
      alt='App Switcher'
    />
  </Dropdown.Toggle>
)

interface AppSwitcherItemProps {
  url: string
  logo: string
  alt: string
  name: string
}

const AppSwitcherItem = ({ url, logo, alt, name }: AppSwitcherItemProps) => {
  return (
    <Dropdown.Item
      className='p-0'
      as='li'
    >
      <a
        href={url}
        className='dropdown-link link-unstyled'
        target='_blank'
      >
        <div className='d-flex align-items-center px-3 py-2 border-bottom'>
          <img
            className='mr-3'
            width={21}
            height={21}
            src={logo}
            alt={alt}
          />
          <strong>{name}</strong>
        </div>
      </a>
    </Dropdown.Item>
  )
}

const AppSwitcherItemList = () => {
  return (
    <Dropdown.Menu
      as='ul'
      className='mt-1 p-0 border-0 rounded-0 shadow'
      alignRight={true}
    >
      {
        hetznerApps.map((item, index) => {
          return (
            <AppSwitcherItem
              key={'key-dropdown-apps' + index}
              {...item}
            />
          )
        })
      }
    </Dropdown.Menu>
  )
}

class AppSwitcher extends React.Component {
  render () {
    return (
      <Dropdown className='dropdown ml-auto'>
        <AppSwitcherButton />
        <AppSwitcherItemList />
      </Dropdown>
    )
  }
}

export default AppSwitcher
