/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react')
const ReactDOM = require('react-dom/client')
const RedirectWrapper = require('./src/components/Various/RedirectWrapper').default
const MenuProvider = require('./src/context/menu-context').default
const PanelProvider = require('./src/context/panel-context').default
const LanguageProvider = require('./src/context/language-context').default

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  return (
    <RedirectWrapper { ...props }>
      <MenuProvider { ...props }>
        <LanguageProvider { ...props }>
          <PanelProvider { ...props }>
            { element }
          </PanelProvider>
        </LanguageProvider>
      </MenuProvider>
    </RedirectWrapper>
  )
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  if (typeof window !== 'undefined') {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
  }

  if (location.hash.length > 0) {
    const id = location.hash.replace('#', '')
    const el = document.getElementById(id)
    if (el !== null) {
      el.scrollIntoView()
    }
  }
}
